import { Injectable } from '@angular/core';
import { HttpClientHelperService } from '../../../shared/services/http-client-helper.service';
import { environment } from "../../../../environments/environment";
import { FileService } from '../../../shared/services/file.service';
import { NgxToastrService } from '../../../shared/services/toastr.service';

@Injectable({
    providedIn: 'root'
})

export class OperationService {


    constructor(private httpClientHelperService: HttpClientHelperService,
        private toastrService: NgxToastrService,
        private fileService: FileService) { }

    // sales 
    getSalesProducts(activityName: string) {
        return this.httpClientHelperService.post<any>
            (`${environment.baseUrl}/api/v1/Sales/GetSalesProducts`, {}, activityName);
    }

    addSales(request: any, activityName: string) {
        return this.httpClientHelperService.post<any>
            (`${environment.baseUrl}/api/v1/Sales/AddSales`, request, activityName);
    }

    addSalesReturn(request: any, activityName: string) {
        return this.httpClientHelperService.post<any>
            (`${environment.baseUrl}/api/v1/Sales/AddSalesReturn`, request, activityName);
    }

    saveSales(request: any, activityName: string) {
        return this.httpClientHelperService.post<any>
            (`${environment.baseUrl}/api/v1/Sales/EditSales`, request, activityName);
    }

    getSalesDetails(salesId: any, activityName: string) {
        return this.httpClientHelperService.post<any>
            (`${environment.baseUrl}/api/v1/Sales/GetSalesDetails`, { id: salesId }, activityName);
    }

    getSalesListByBranch(activityName: string) {
        return this.httpClientHelperService.post<any>
            (`${environment.baseUrl}/api/v1/Sales/GetSalesListByBranch`, {}, activityName);
    }

    getTax(activityName: string) {
        return this.httpClientHelperService.post<any>
            (`${environment.baseUrl}/api/v1/Sales/GetTax`, {}, activityName);
    }

    downloadInvoice(salesId) {
        this.getInvoiceFile({ salesId: salesId }).subscribe(response => {
            this.toastrService.manageApiMessages(response)
            if (response.data) {
                var fileToDownload = this.fileService.b64toBlob(response.data.fileBase64Content, response.data.mimeType, response.data.fileName);
                this.fileService.downloadFile(fileToDownload);
            }
        })
    }

    getInvoiceFile(request: any) {
        return this.httpClientHelperService.post<any>
            (`${environment.baseUrl}/api/v1/Sales/DownloadInvoice`, request, '');
    }

    getCashCategories(activityName: string) {
        return this.httpClientHelperService.post<any>
            (`${environment.baseUrl}/api/v1/Cash/GetCashCategories`, {}, activityName);
    }

    AddCashCategory(name: string, activityName: string) {
        return this.httpClientHelperService.post<any>
            (`${environment.baseUrl}/api/v1/Cash/AddCashCategory`, { name: name }, activityName);
    }

    addCash(request: any, activityName: string) {
        return this.httpClientHelperService.post<any>
            (`${environment.baseUrl}/api/v1/Cash/AddCash`, request, activityName);
    }

    editCash(request: any, activityName: string) {
        return this.httpClientHelperService.post<any>
            (`${environment.baseUrl}/api/v1/Cash/EditCash`, request, activityName);
    }

    deleteCash(id: any, activityName: string) {
        return this.httpClientHelperService.post<any>
            (`${environment.baseUrl}/api/v1/Cash/DeleteCash`, { id: id }, activityName);
    }


    getUserBalance(activityName: string) {
        return this.httpClientHelperService.post<any>
            (`${environment.baseUrl}/api/v1/User/GetUserBalance`, {}, activityName);
    }

    emptyUserBalance(activityName: string) {
        return this.httpClientHelperService.post<any>
            (`${environment.baseUrl}/api/v1/User/EmptyUserBalance`, {}, activityName);
    }

    onGetActivites(): any[] {
        let user = JSON.parse(localStorage.getItem('current-user'));
        return user.permissions;
    }

    isActivityAllowedByUser(activityName: string): boolean {
        let activities = this.onGetActivites();
        return activities.filter(a => a == activityName).length > 0
    }
}