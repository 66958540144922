export enum APIResponseEnum {
    Success = 1,
    Failure = 0,
    Exception = -1
  }
  
  export enum APIMessagesEnum {
    Business = 1,
    Technical,
    Information
  }