<h1 id="title" class="title">Login</h1>
<p class="sub-title">Hello! Log in with your email.</p>

<nb-alert *ngIf="showMessages.error && errors?.length && !submitted" outline="danger" role="alert">
  <p class="alert-title"><b>Oh snap!</b></p>
  <ul class="alert-message-list">
    <li *ngFor="let error of errors" class="alert-message">{{ error }}</li>
  </ul>
</nb-alert>

<nb-alert *ngIf="showMessages.success && messages?.length && !submitted" outline="success" role="alert">
  <p class="alert-title"><b>Hooray!</b></p>
  <ul class="alert-message-list">
    <li *ngFor="let message of messages" class="alert-message">{{ message }}</li>
  </ul>
</nb-alert>

<form (ngSubmit)="login()" #form="ngForm" aria-labelledby="title">

  <div class="form-control-group">
    <label class="label" for="input-email">Email address:</label>
    <input nbInput
           fullWidth
           [(ngModel)]="user.email"
           #email="ngModel"
           name="email"
           id="input-email"
           pattern=".+@.+\..+"
           placeholder=""
           autofocus
           [status]="email.dirty ? (email.invalid  ? 'danger' : 'primary') : ''"
           [required]="getConfigValue('forms.validation.email.required')"
           [attr.aria-invalid]="email.invalid && email.touched ? true : null">
    <ng-container *ngIf="email.invalid && email.touched">
      <p class="error-message" *ngIf="email.errors?.required">
        Email is required!
      </p>
      <p class="error-message" *ngIf="email.errors?.pattern">
        Email should be the real one!
      </p>
    </ng-container>
  </div>

  <div class="form-control-group">
    <label class="label" for="input-password">Password:</label>
    <input nbInput
           fullWidth
           [(ngModel)]="user.password"
           #password="ngModel"
           name="password"
           id="input-password"
           placeholder=""
           [status]="password.dirty ? (password.invalid  ? 'danger' : 'primary') : ''"
           [required]="getConfigValue('forms.validation.password.required')"
           [minlength]="4"
           [maxlength]="20"
           [attr.aria-invalid]="password.invalid && password.touched ? true : null">
    <ng-container *ngIf="password.invalid && password.touched ">
      <p class="error-message" *ngIf="password.errors?.required">
        Password is required!
      </p>
      <p class="error-message" *ngIf="password.errors?.minlength || password.errors?.maxlength">
        Password should contains
        from {{ getConfigValue('forms.validation.password.minLength') }}
        to {{ getConfigValue('forms.validation.password.maxLength') }}
        characters
      </p>
    </ng-container>
  </div>


  <button nbButton
          fullWidth
          status="primary"
          style="background-color: #112D68;"
          [disabled]="submitted || !form.valid"
          [class.btn-pulse]="submitted">
          LogIn  </button>
</form>



